
 $(function () {
   var inTheKnowOn;
   // If showInTheKnowOn do is not defined we will continue with the normal flow and show inTheKnowOn
   // else it will depend on the value of the variable
   if(typeof showInTheKnowOn === 'undefined' ) {
     inTheKnowOn = true;
   } else {
     inTheKnowOn = showInTheKnowOn;
   }
   if(inTheKnowOn) {
    var minSecondOnPage = 10,
        secondsSincePageStart = 0,
        secondsSinceSessionStart = $('#visitSessionStat').data('secondsincestart'),
        nIntervId = setInterval(function() {
          secondsSincePageStart++;
          if ( secondsSincePageStart % minSecondOnPage  === 0 ) {
            clearInterval(nIntervId);
            var now = new Date();
            var cookieName = 'Aggregage-popUpSub';
            if ( $.cookie(cookieName) == null && $('.modal.in').length === 0) {
              // Call function in login-register.js
              showPopUpSignUpForm();
              $.cookie(cookieName, now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(), { expires: 30, path : '/', secure: true });
            }
          }
          $('#visitSessionStat').data('secondonpage', secondsSincePageStart);
            $('#pageSeconds').text(secondsSinceSessionStart + ' + ' + secondsSincePageStart);
        }, 1000),
        // check on number of search 
        minSearch = 2, 
        searchCount = $('#visitSessionStat').data('searchcount'),
        cookieName = 'Aggregage-popUpSub';
    
    $('#timeOnPage').html('<span id="pageSeconds">' + secondsSinceSessionStart + '</span>');
    
    if ( searchCount > minSearch ) {
      var now = new Date();
      
      clearInterval(nIntervId);
      if ( $.cookie(cookieName) == null && $('.modal.in').length === 0) {
        // Call function in login-register.js
        showPopUpSignUpForm();
        $.cookie(cookieName, now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(), { expires: 30, path : '/', secure: true });
      }      
    }
    
    //When scroll to the bottom of the page
    $(window).scroll(function() {
      var now = new Date();
      
      if ($(window).scrollTop() == $(document).height() - $(window).height()) {
        // check seconds spend on the page
        if ( $.cookie(cookieName) == null && $('.modal.in').length === 0) {
          // Call function in login-register.js
          showPopUpSignUpForm();
          $.cookie(cookieName, now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(), { expires: 30, path : '/', secure: true });
        }
      }
    });
    
    //Check/count if a link is clicked 
    $('a').on('click', function(event) {
       var href = event.target.href, // TODO: check on the URL, undefined
           clickCount = $('#visitSessionStat').data('clickcount') + 1,
           now = new Date();
       $('#visitSessionStat').data('clickcount', clickCount);
       if (clickCount > 5) {
         if ( $.cookie(cookieName) == null && $('.modal.in').length === 0) {
           showPopUpSignUpForm();
           $.cookie(cookieName, now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(), { expires: 30, path : '/', secure: true });
         }
       }
    });
    
    if ($('#visitSessionStat').data('clickcount') > 5 ) {
      var now = new Date();
      if ( $.cookie(cookieName) == null && $('.modal.in').length === 0) {
        showPopUpSignUpForm();
        $.cookie(cookieName, now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(), { expires: 30, path : '/', secure: true });
      }
    }
  }    
});
