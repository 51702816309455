/**
 * Task #17666: Initially render all nofollow links as <span> tags then
 * upon page load replace them with the equivalent <a> tag. This is to discourgae bots
 * from following our links to other blogs.
 */
$(function() {
  $('.nfl').each(function() {
    var oldElement = this,
        newElement = $('<a></a>');

    // Copy over all the attributes. IE7 is lame so we have to check if its defined.
    for (var i = 0, n = oldElement.attributes.length; i < n; i++) {
      var attribute = oldElement.attributes[i];
      if (attribute.specified) {
        newElement.attr(attribute.name, attribute.value);
      }
    }
    newElement.removeClass('nfl');
    
    // Copy over the elements inner html.
    newElement.html($(oldElement).html());
    
    // Replace the element in the DOM.
    $(oldElement).replaceWith(newElement);
  });
});