function layoutMasonry($grid) {
  var div = $('.parallax');
  var width = div.width();
  div.css('height', width * 0.7);

  if ($(".bb-join-block").is(":visible") == true) { 
    $grid.masonry( 'stamp', $(".bb-join-block") );
  }
  else {
    $grid.masonry( 'unstamp', $(".bb-join-block") );
  }
  $grid.masonry('layout');
  $(".sign-up-btn-number").slabText({"fontRatio" : 0.9});
}

function shiftParallaxImages() {
  $(".parallax img").each(function( index ) {
    var wst = $(window).scrollTop();
    var wsb = wst + $(window).height();
    var pot = $(this).parent().offset().top;
    var pob = pot + $(this).parent().height();

    if (pot<wsb && pob>wst) {
      $(this).css({'transform' : 'translateY(' + (wst-pot)*25 / ($(window).height()-$(this).parent().height()).toFixed() + 'px)'});
    }
  });
}

$.urlParam = function(name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
    return null;
  }
  else {
    return results[1] || 0;
  }
}

$(function() {
 
  $('[data-toggle=relatedTopicsPopover]').popover({
    html: true, 
    content: function() {
      return $('#popover-content').html();
    }
  });
  
  $('[data-toggle=articleRelatedTopicsPopover]').popover({
    html: true, 
    container: 'body',
    content: function() {
      return $(this).parent().find(".article-related-topics").html();
    }
  });
  $('[data-toggle=articleRelatedTopicsPopover]').on('shown.bs.popover', function () {
    initializeKeywordButtons();
  })

  // init Masonry
  var $grid = $('.grid').masonry({
    itemSelector : '.bb-article-block',
    stamp: '.bb-join-block', 
    columnWidth : '.grid-sizer'
  });
  
  layoutMasonry($grid);
  
  function scrollToRequestedArticle() {
    var articleId = $.urlParam('article-id');
    if (articleId != null) {
      $('.article-card-' + articleId).removeClass('compact').addClass('expanded');
      layoutMasonry($grid);
      setTimeout(function() {
        layoutMasonry($grid);
      }, 500);
      
      var scrollPos = $('.article-card-' + articleId).filter(':visible:first').first().offset().top - 140; // -90 (navbar dif) -50 (fixed navbar) 
      $('html, body').animate({scrollTop: scrollPos + 'px'}, 'fast');
      
      if ($(window).width() > 560 ) {
        $('#article-details-' + articleId).modal('show');
      }
    }
  }
  
  // layout Masonry after all images loads
  $grid.imagesLoaded().always( function() {
    layoutMasonry($grid);
    scrollToRequestedArticle();
  });

  $(window).on("resize", function() {
    layoutMasonry($grid)
  });

  $(window).on("scroll", function() {
    shiftParallaxImages();
  });
  
  $('.open-article-details').on("click", function() {
      var contentPanel = $(this).closest(".bb-article-block");
      
      contentPanel.removeClass('compact').addClass('expanded');
      
      layoutMasonry($grid);
      setTimeout(function() {
        layoutMasonry($grid);
      }, 500);
  });
});
