$(document).ready(function() {
  $('a').on("click", function() {
    var $a = $(this);
    var href = $a.attr('href');

    $.ajax({
      url: _trackerUrl + "&destinationUrl=" + href,
      headers: { 'X-Alt-Referer': window.location.href }
    });
  });
});