$(function() {
  $("td#sidebar > ul, #browseViewMoreKeywordsTable td").find("a").not(".more, .backLink-more a").each(function() {
    $this = $(this);
    var label;
    var selected = false;
    var $bold = $this.find("b");
    if ($bold.length !== 0)
    {
      // This is a selected keyword, look for the label in the <b> tag
      selected = true;
      label = $bold.html();
    }
    else
    {
      // It's a regularly listed keyword, the label is in the html of this <a> tag
      label = $this.html();
    }
    // Remove the keyword count and preceding empty space from the returned html
    var indexOfParenthesis = label.lastIndexOf('(');
    if (indexOfParenthesis > -1)
    {
      label = label.substring(0, indexOfParenthesis - 1);
    }
    if (selected)
    {
      $this.prop("title", "Exclude \"" + label + "\" from the search");
    }
    else
    {
      $this.prop("title", "Include \"" + label + "\" in the search");
    }
  });
});