$(document).ready(function() {
  $('a').click(function() {
    var $a = $(this);
    var href = $a.attr('href');

    if (typeof href != 'undefined' && href.match(/^http/)) 
    {
      if (!href.match(document.domain) || href.includes("open-article-id="))
      {
        var category = 'outgoing';
        var event = 'click';
        var label = href;

        ga('send', 'event', category, event, label);
        $a.target = "_blank";
        return true;
      }
      else 
      {
        var target = $a.attr('target');
        if (typeof target != 'undefined' && target.match('_blank') && href.indexOf('go/r/10/') > -1)
        { 
          $.ajax({
            type: 'POST',
            url: href.replace('go/r/10/', 'go/r/21/'),
            success: function(data) {
              var category = 'outgoing';
              var event = 'click';
              var label = data.url;
              ga('send', 'event', category, event, label);
              return true;
            }
          });
        }
      } 
    }
  });
  
  // Track article details opening
  $('.ga-article-detail').on('shown.bs.modal', function (e) {
    var href = $(this).find('a.article-title').attr('href');
    ga('send', 'event', 'articleDetails','show',href);
  });

  $('a.st_facebook_custom').click(function() {
    var url = $(this).attr('data-share-url');
    ga('send', 'event', 'shareThis','facebook',url);
  });
  $('a.st_linkedin_custom').click(function() {
    var url = $(this).attr('data-share-url');
    ga('send', 'event', 'shareThis','linkedin',url);
  });
  $('a.st_twitter_custom').click(function() {
    var url = $(this).attr('data-share-url');
    ga('send', 'event', 'shareThis','twitter',url);
  });


  // Track start of sign up process (login-register.js - steps)
  $('#newLoginForm').on('shown.bs.modal', function (e) {
      ga('send', 'event', 'loginSignupForm','show');
  });
  // Track sign up commands
  $('[name="user-login-registration-form-submit"]').click(function() {
      ga('send', 'event', 'signup','submit');
  });
  // Track sign in commands
  $('#lhlogin').click(function() {
      ga('send', 'event', 'login','submit');
  });
  
  // Track sign up command from the page body
  $('#main-content input[name="user-registration-form-submit"]').click(function() {
      ga('send', 'event', 'signup','submit');
  });
  
  
  // Track start of sign up process (login-register.js - steps)
  $('#newForgotPasswordForm').on('shown.bs.modal', function (e) {
      ga('send', 'event', 'ForgotPasswordForm','show');
  });
  // Track sign in commands
  $('#newForgotPasswordForm input[type="submit"]').click(function() {
      ga('send', 'event', 'ForgotPasswordForm','submit');
  });
  
  
  // Track start of sign up process (login-register.js - steps)
  $('.complete-registration-form').on('shown.bs.modal', function (e) {
      ga('send', 'event', 'CompleteRegistrationForm','newsletterTab');
  });
  
  
  // Track start of pop-up sign up modal window
  $('#newRegistrationForm').on('shown.bs.modal', function (e) {
      ga('send', 'event', 'PopUpNewRegistrationForm','show');
  });
  // Track submitting the pop-up sign up modal window
  $('#newRegistrationForm input[name="user-registration-form-submit"]').click(function () {
      ga('send', 'event', 'PopUpNewRegistrationForm', 'submit');
  })
  
    
  // Track supporting pages buttons
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      ga('send', 'event', 'supporting','show',e.target.href);
  })

  // Track more topics commands
  $('a.more-topics-link').click(function() {
      ga('send', 'event', 'moreTopics','click',$(this).attr('href'));
  });
  
  // Track more topics commands
  $('a.more-related-topics-link').click(function() {
      ga('send', 'event', 'moreRelatedTopics','click',$(this).attr('href'));
  });
  
});
